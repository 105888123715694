import axios from "@/plugins/axios";
import authHeader from '../auth-header'
import ConfigService from "../config";

const API_URL = ConfigService.base_url;

class CouponsServiceApi {
  getAllCoupons(page=1) {
    return axios
      .get(API_URL + "admin/coupons", {
        params:{ 
          paginate:10,
          page:page
        },
        headers: authHeader()
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  couponAction(data) {
    return axios
      .post(API_URL + "admin/coupons",data,
      {
        headers: authHeader()
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

}

export default new CouponsServiceApi();
