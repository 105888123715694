<script>
import Layout from "../../layouts/main";
import CouponsServiceApi from "@/service/api/coupons";
import Spinner from "@/components/spinner";
export default {
  components: { Layout, Spinner },
  data() {
    return {
      couponsData: null,
      fields: [
        { key: "id", sortable: false },
        { key: "code", sortable: false },
        { key: "value", sortable: false },
        { key: "expiry", sortable: false },
        { key: "created_at", sortable: false },
        { key: "updated_at", sortable: false },
        { key: "coupon_type", sortable: false },
        { key: "applicable_for", sortable: false },
      ],
      rows: 0,
      perPage: 10,
      selectedCoupon: null,
      coupon_type_opt:[
        { value: 'reusable', label: 'Reusable'},
        { value: 'new_user', label: 'New User'},
        { value: 'one_time', label: 'One Time'},

      ],
      addingCoupon: false,
      deletingCoupon: false,
      currentPage: 1,
      isPercentage: false,
      loading: false,
      applicableFor: {
        transaction: false,
        processing: false,
        shipping: false,
      },

      coupon_type:null,
      couponCode: null,
      expiryDate: null,
      value: null,
      dateValidation:
        "required|after:" +
        `${this.moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD")}`,
      couponValueValidation:"required|min:1"
    };
  },
  computed: {

  },
  mounted() {
    this.loadCouponData();
  },
  watch: {
    currentPage() {
      this.loadCouponData();
    },
    isPercentage(val){
      if(val){
          this.couponValueValidation ="required|min:1|max:100"
      }
      else{
          this.couponValueValidation ="required|min:1"
      }
    }
    
  },
  methods: {
    loadCouponData() {
      this.loading = true;
      CouponsServiceApi.getAllCoupons(this.currentPage)
        .then((response) => {
          if (response.data) {
            this.couponsData = response.data.coupons.data.map((item)=>{
              item.coupon_type =  this.parseCouponType(item)
              return item;
            })
            this.rows = response.data.coupons.total;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    parseCouponType(data){
      if(data.new_user ==="1"){
          return "new_user"
      }
      else if(data.one_time ==="1"){
        return "one_time"
      }
      else{
        return "reusable"
      } 

    },
    couponClicked(val) {
      this.selectedCoupon = val;
      this.applicableFor.transaction = val.is_transaction;
      this.applicableFor.processing = val.is_processing;
      this.applicableFor.shipping = val.is_shipping;
      this.couponCode = val.code;
      this.expiryDate = this.moment(val.expiry).format("YYYY-MM-DD");
      this.value = val.value;
      this.isPercentage = val.is_percentage;
      this.coupon_type = val.coupon_type;
      this.$refs.EditCoupon.show();
    },
    submitCouponHandler() {
      this.submitForm();
    },
    resetForm() {
      this.$refs.EditCoupon.hide();
      this.resetFormData();
    },
    deleteCoupon(item) {
      this.selectedCoupon = item;
      this.$refs.DeleteCoupon.show();
    },
    closeDeleteModal() {
      this.selectedCoupon = null;
      this.$refs.DeleteCoupon.hide();
    },
    addCoupon() {
      this.resetFormData();
      this.coupon_type = "reusable";
      this.$refs.EditCoupon.show();
    },
    resetFormData() {
      this.applicableFor = {
        transaction: false,
        processing: false,
        shipping: false,
      };
      this.isPercentage = false;
      this.selectedCoupon = null;
      this.couponCode = null;
      this.expiryDate = null;
      this.value = null;
    },
    submitForm() {
      const dataSubmit = {
        code: this.couponCode,
        value: this.value,
        is_percentage: this.isPercentage,
        is_shipping: this.applicableFor.shipping,
        is_transaction: this.applicableFor.transaction,
        is_processing: this.applicableFor.processing,
        expiry: this.expiryDate,
        coupon_type:this.coupon_type,
      };
      dataSubmit.action = "add";
      if (this.selectedCoupon) {
        dataSubmit.id = this.selectedCoupon.id;
        dataSubmit.action = "edit";
      }
      this.addingCoupon = true;

      CouponsServiceApi.couponAction(dataSubmit)
        .then((response) => {
          if (response.data.success) {
            this.$refs.EditCoupon.hide();
            this.couponSuccessMessage(
              dataSubmit.action,
              response.data.coupon.code
            );
            this.loadCouponData();
            this.resetFormData();
          } else {
            this.couponErrorMessage(dataSubmit.action, response.data.error);
          }
        })
        .finally(() => {
          this.addingCoupon = false;
        });
    },

    couponErrorMessage(action, error) {
      this.couponActionMessage(
        `Coupon ${action}`,
        `${action} is failed with the message : ${error}`,
        "danger"
      );
    },
    couponDeleteMessage() {
      this.couponActionMessage(
        `Coupon delete`,
        "Coupon is deleted successfully!"
      );
    },
    couponSuccessMessage(action, coupon) {
      this.couponActionMessage(
        `Coupon ${action}`,
        action === "edit"
          ? `Coupon ${coupon} updated successfully!`
          : `Coupon ${coupon} added successfully!`
      );
    },
    couponActionMessage(title, message, variant = "success") {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    removeCoupon(id) {
      const dataSubmit = {
        action: "delete",
        id: id,
      };
      this.deletingCoupon = true;
      CouponsServiceApi.couponAction(dataSubmit)
        .then((response) => {
          if (response.data.success) {
            this.$refs.DeleteCoupon.hide();
            this.couponDeleteMessage();
            this.loadCouponData();
            this.resetFormData();
          } else {
            this.couponErrorMessage(dataSubmit.action, response.data.error);
          }
        })
        .finally(() => {
          this.deletingCoupon = false;
        });
    },
    isActiveCoupon(item) {
      return this.moment(item) > this.moment();
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div>
              <Spinner :show="loading" />
            </div>
            <div class="coupon-nav-st">
              <div class="float-left">
                <span class="pr-2">
                  Active
                  <i
                    class="ri-checkbox-blank-circle-fill"
                    style="color:green;font-size:8px;"
                  ></i>
                </span>
                <span>
                  Inactive
                  <i
                    class="ri-checkbox-blank-circle-fill"
                    style="color:red;font-size:8px;"
                  ></i>
                </span>
              </div>
              <b-button
                variant="light waves-effect waves-light float-right"
                @click="addCoupon"
              >
                <i class="ri-add-box-fill align-middle"></i> Add
                Coupon</b-button
              >
            </div>

            <div
              v-if="couponsData && !couponsData.length"
              style="text-align:center;font-size:24px;font-weight:900;padding-top:100px;padding-bottom:100px"
            >
              No Coupon
            </div>
            <div v-else>
              <div class="table-responsive mb-0">
                <b-table
                  :items="couponsData"
                  :fields="fields"
                  responsive="sm"
                  @row-clicked="couponClicked"
                  selectable
                >
                  <template v-slot:cell(expiry)="row">
                    <div v-if="row.value">
                      {{ moment(row.value).format("MMMM Do YYYY") }}
                      <br />
                      {{ moment(row.value).format("h:mm:ss a") }}
                    </div>
                  </template>
                  <template v-slot:cell(created_at)="row">
                    <div v-if="row.value">
                      {{ moment(row.value).format("MMMM Do YYYY") }}
                      <br />
                      {{ moment(row.value).format("h:mm:ss a") }}
                    </div>
                  </template>
                  <template v-slot:cell(updated_at)="row">
                    <div v-if="row.value">
                      {{ moment(row.value).format("MMMM Do YYYY") }}
                      <br />
                      {{ moment(row.value).format("h:mm:ss a") }}
                    </div>
                  </template>
                  <template v-slot:cell(coupon_type)="row">
                      {{ coupon_type_opt.filter((item)=> item.value=== row.value)[0].label }}
                  </template>
                  <template v-slot:cell(value)="row">
                    {{ parseInt(row.value) }}
                    <span v-if="row.item.is_percentage">%</span>
                    <span v-else>AED</span>
                  </template>
                  <template v-slot:cell(applicable_for)="row">
                    <div v-if="row.item" style="width:140px;">
                      <div
                        class="row"
                        style="background-color: antiquewhite;
                          border-radius: 12px;"
                      >
                        <div class="col-8">
                          Transaction
                        </div>
                        <div class="col-4">
                          <i
                            class="ri-check-fill coupons-applicable "
                            v-if="row.item.is_transaction"
                          ></i>
                          <i
                            v-else
                            class="ri-close-fill coupons-not-applicable "
                          ></i>
                        </div>
                      </div>
                      <div
                        class="row"
                        style="background-color: #f1f1f1;
                          border-radius: 12px;margin-top:2px"
                      >
                        <div class="col-8">
                          Processing
                        </div>
                        <div class="col-4">
                          <i
                            class="ri-check-fill coupons-applicable "
                            v-if="row.item.is_processing"
                          ></i>
                          <i
                            v-else
                            class="ri-close-fill coupons-not-applicable "
                          ></i>
                        </div>
                      </div>
                      <div
                        class="row"
                        style="background-color:azure;
                            border-radius: 12px;margin-top:2px"
                      >
                        <div class="col-8">
                          Shipping
                        </div>
                        <div class="col-4">
                          <i
                            class="ri-check-fill coupons-applicable "
                            v-if="row.item.is_shipping"
                          ></i>
                          <i
                            v-else
                            class="ri-close-fill coupons-not-applicable "
                          ></i>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-slot:cell(code)="row">
                    <span style="font-size:14px;font-weight:900">
                      {{ row.value }}
                      <i
                        v-if="isActiveCoupon(row.item.expiry)"
                        class="ri-checkbox-blank-circle-fill"
                        style="color:green;font-size:8px;"
                      ></i>
                      <i
                        v-else
                        class="ri-checkbox-blank-circle-fill"
                        style="color:red;font-size:8px;"
                      ></i>
                      <br />
                      <button
                        class="btn btn-danger btn-sm"
                        style="height:20px;width:50px;padding:0px"
                        @click="deleteCoupon(row.item)"
                      >
                        Delete
                      </button>
                    </span>
                  </template>
                </b-table>
              </div>
              <div class="row" style="">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers">
                    <ul class="pagination pagination-rounded mb-0 float-right">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      ref="EditCoupon"
      id="EditCoupon"
      hide-footer
      centered
      title="Edit Coupon"
    >
      <FormulateForm name="couponForm" @submit="submitCouponHandler">
        <div class="m-2">
          <div class="row mb-4">
            <div class="col-6">
              <FormulateInput
                type="text"
                name="Code"
                label="Coupon"
                v-model="couponCode"
                validation="required"
                error-behavior="submit"
              />
            </div>
            <div class="col-6 ">
              <FormulateInput
                type="date"
                name="Expiry Date"
                v-model="expiryDate"
                label="Expiry Date"
                placeholder="Expiry Date"
                :validation="dateValidation"
                error-behavior="submit"
              />
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-12" style="font-weight:900;margin-bottom:8px;">
              Applicable for
            </div>
            <div class="col-4">
              <FormulateInput
                v-model="applicableFor.transaction"
                type="checkbox"
                label="Transaction"
              />
            </div>
            <div class="col-4">
              <FormulateInput
                v-model="applicableFor.processing"
                type="checkbox"
                label="Proceesing"
              />
            </div>
            <div class="col-4">
              <FormulateInput
                v-model="applicableFor.shipping"
                type="checkbox"
                label="Shipping"
              />
            </div>
          </div>

          <div class="row mb-4">
          
            <div class="col-12" id="typeCoupon">
              <FormulateInput
                v-model="coupon_type"
                :options="coupon_type_opt"
                type="radio"
                label="Type"
                validation="required"
                error-behavior="submit"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-4" style="padding-top:8px;font-weight:900">
              <div>
                Is percentage?
              </div>
              <div style="padding-top:10px">
                <b-form-checkbox
                  name="check-button"
                  v-model="isPercentage"
                  switch
                >
                </b-form-checkbox>
              </div>
            </div>
            <div class="col-8">
              <FormulateInput
                type="number"
                name="Value"
                label="Value"
                v-model="value"
               :validation="couponValueValidation"
                error-behavior="submit"
              />
            </div>
          </div>
        </div>
        <div style="padding-bottom:60px;">
          <div class="text-center mt-4">
            <button
              class="btn btn-warning w-md waves-effect waves-light float-right"
              type="submit"
              v-if="selectedCoupon"
            >
              <span v-if="addingCoupon">Updating...</span>
              <span v-else>
                Update
              </span>
            </button>
            <button
              class="btn btn-secondary w-md waves-effect waves-light float-right"
              type="submit"
              v-else
            >
              <span v-if="addingCoupon"> Adding...</span>
              <span v-else>
                Add
              </span>
            </button>

            <button
              class="btn btn-primary w-md waves-effect waves-light float-left"
              type="button"
              @click="resetForm"
            >
              Cancel
            </button>
          </div>
        </div>
      </FormulateForm>
    </b-modal>

    <b-modal
      ref="DeleteCoupon"
      id="DeleteCoupon"
      hide-footer
      centered
      title="Delete Coupon"
    >
      <div
        v-if="selectedCoupon"
        class="p-4"
        style="text-align:center;font-size:large"
      >
        Are you sure,do you want to delete the token
        <div style="font-weight:900">{{ selectedCoupon.code }} !</div>
      </div>
      <div style="padding-bottom:60px;">
        <div class="text-center mt-4">
          <button
            class="btn btn-danger w-md waves-effect waves-light float-right"
            type="button"
            @click="removeCoupon(selectedCoupon.id)"
          >
            <span v-if="deletingCoupon">
              Deleting...
            </span>
            <span v-else>
              Delete
            </span>
          </button>

          <button
            class="btn btn-primary w-md waves-effect waves-light float-left"
            type="button"
            @click="closeDeleteModal"
          >
            Cancel
          </button>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>

<style>
.coupons-applicable {
  color: green;
  font-weight: 900;
  font-size: 18px;
}
.coupons-not-applicable {
  color: red;
  font-weight: 900;
  font-size: 18px;
}

#EditCoupon
  .formulate-input[data-classification="box"]
  .formulate-input-element {
  padding-top: 8px;
}

.coupon-nav-st {
  margin-bottom: 60px;
}
#typeCoupon .formulate-input-group{
  display:flex;
  justify-content:space-between;
  margin-right:3rem;

}
#typeCoupon .formulate-input[data-classification=group] > .formulate-input-wrapper > .formulate-input-label {
    margin-bottom: 0;
}
</style>
